/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import dynamic from 'next/dynamic';

import { FRONTEND_DOMAIN } from '../../../constants/api';

import styles from './CrackUpscCse.module.scss';

const CourseItemComponent = dynamic(() => import('../CourseItem'));

const ComboBatches = dynamic(() => import('../../../assets/images/crack-upsc-cse/combo-batches.svg'));
const LiveClassImg = dynamic(() => import('../../../assets/images/crack-upsc-cse/live-class.svg'));
const UpscOptional = dynamic(() => import('../../../assets/images/crack-upsc-cse/optional.svg'));
const TestSeries = dynamic(() => import('../../../assets/images/crack-upsc-cse/test-series.svg'));
const OurEducatorsImg = dynamic(() => import('../../../assets/images/our-educators.svg'));

function CrackUpscCseComponent({ courses }) {
  return (
    <React.Profiler id="CrackUpscCseComponent" onRender={console.log}>
      <section data-testid="crack-upsc-cse-section" className={styles.wrapper}>
        <div className={styles.leftSection}>
          <div className={styles.titleWrapper}>
            <h3 className={styles.title}>
              Crack UPSC CSE - GS with Study<span>IQ</span>
            </h3>
          </div>

          <div className={styles.topEducatorsWrapper}>
            <OurEducatorsImg className={styles.topEducators} />
            <h5 className={styles.title}>India&apos;s top educators</h5>
            <h5 className={styles.subtitle}>Learn with the best educators for online UPSC CSE - GS preparation</h5>
          </div>
        </div>
        <div className={styles.rightSection}>
          <div className={styles.courseItemsWrapper}>
            {courses?.[0] && (
              <React.Profiler id="CrackUpscCseComponent0" onRender={console.log}>
                <CourseItemComponent
                  title={courses?.[0]?.heading}
                  subtitle={courses?.[0]?.content || null}
                  color="#E1EDEE"
                  icon={<LiveClassImg />}
                  link={{
                    href: `${FRONTEND_DOMAIN}/${courses?.[0]?.link}`,
                    label: 'Explore Courses',
                  }}
                  data-testid="course-item-link"
                />
              </React.Profiler>
            )}

            {courses?.[2] && (
              <React.Profiler id="CrackUpscCseComponent2" onRender={console.log}>
                <CourseItemComponent
                  title={courses?.[2]?.heading}
                  subtitle={courses?.[2]?.content || null}
                  color="#D5EEDB"
                  icon={<TestSeries />}
                  link={{
                    href: `${FRONTEND_DOMAIN}/${courses?.[2]?.link}`,
                    label: 'Explore Courses',
                  }}
                  data-testid="course-item-link"
                />
              </React.Profiler>
            )}
          </div>

          <div className={styles.courseItemsWrapper}>
            {courses?.[1] && (
              <React.Profiler id="CrackUpscCseComponent1" onRender={console.log}>
                <CourseItemComponent
                  title={courses?.[1]?.heading}
                  subtitle={courses?.[1]?.content || null}
                  color="#FBF2E4"
                  icon={<UpscOptional />}
                  link={{
                    href: `${FRONTEND_DOMAIN}/${courses?.[1]?.link}`,
                    label: 'Explore Courses',
                  }}
                  data-testid="course-item-link"
                />
              </React.Profiler>
            )}

            {courses?.[3] && (
              <React.Profiler id="CrackUpscCseComponent3" onRender={console.log}>
                <CourseItemComponent
                  title={courses?.[3]?.heading}
                  subtitle={courses?.[3]?.content || null}
                  color="#E9EFFB"
                  icon={<ComboBatches />}
                  link={{
                    href: `${FRONTEND_DOMAIN}/${courses?.[3]?.link}`,
                    label: 'Explore Courses',
                  }}
                  data-testid="course-item-link"
                />
              </React.Profiler>
            )}
          </div>
        </div>
      </section>
    </React.Profiler>
  );
}

export default CrackUpscCseComponent;
